.button {
  position: relative;
  background: var(--primary);
  height: 3rem;
  border: none;
  font-weight: var(--font-bold);
  font-size: 1rem;
  letter-spacing: 1.5px;
  font-family: Raleway;
  &::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    background: transparent;
    border: 2px solid var(--black);
  }
  &:disabled {
    background: var(--disabled);
  }
}

.header {
  color: var(--primary);
  text-align: center;
  padding-top: 2.5rem;
  &__subheading {
    color: var(--white);
    font-size: 1.25rem;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2.5rem 0;
  max-width: 720px;
  margin: auto;
}

.radio_input {
  &__container {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  &__input {
    height: 1.25rem;
    width: 1.25rem;
  }
  &__label {
    color: var(--white);
    font-weight: var(--font-semibold);
  }
  &__disabled {
    color: var(--disabled);
  }
}

.radio_group {
  border-color: var(--primary);
  padding: 1.5rem;
  padding-top: 1rem;
  &__label {
    color: var(--primary);
    font-weight: var(--font-extrabold);
    font-style: italic;
    padding: 0 0.5rem;
  }
  &__items {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  &__disabled {
    color: var(--disabled);
    border-color: var(--disabled);
  }
}

.modal {
  &__container {
    position: fixed;
    top: 0;
    left: 2rem;
    right: 2rem;
    height: 100%;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.75);
  }
  &__element {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background: var(--black);
    border: 0.25rem solid var(--primary);
    padding: 40px;
    height: auto;
    opacity: 0;
    transition: all 300ms ease-in-out;
    top: 120px;
    padding: 2rem;
    max-width: 360px;
    &.open {
      opacity: 1;
      top: 90px;
      animation: show 300ms ease-in-out;
    }
    @keyframes show {
      from {
        opacity: 0;
        top: 120px;
      }
      to {
        opacity: 1;
        top: 90px;
      }
    }
  }
  &__item {
    color: var(--white);
    display: flex;
    gap: 0.5rem;
    white-space: nowrap;
  }
  &__item_label {
    font-weight: var(--font-extrabold);
  }
  &__close {
    margin-top: 1rem;
  }
}
