.app {
  background: var(--black);
  min-height: 100vh;
  width: 100vw;
  padding: 0 2.5rem;
}

:root {
  --black: #000000;
  --white: #ffffff;
  --primary: #daa520;
  --disabled: #545454;

  --font-semibold: 600;
  --font-bold: 700;
  --font-extrabold: 800;
}
